export const layanan = [
  {
    title: ["Kunjungan", "GRATIS"],
    details: [
      "Bagi klien yang berkantor di wilayah Jakarta dan jumlah pesanan seragam minimal 50pcs, kami melayani kunjungan ke kantor klien selama jam kerja, setelah waktu dan tempat yang disepakati ditentukan.",
    ],
  },
  {
    title: ["Pengukuran", "GRATIS"],
    details: [
      "Untuk memastikan bahwa seragam yang dipesan memiliki ukuran yang tepat dengan tubuh klien, kami menyediakan layanan dalam bentuk mengirimkan pakaian sampel untuk pengukuran, ukuran S, M, L, XL, XXL.",
      "Caranya, klien terlebih dahulu mentransfer jaminan senilai Rp. 300.000 ke gudangseragam.com, maka kami akan mengirimkan satu set pakaian untuk pengukuran. Jika pengukuran telah diselesaikan oleh klien, klien mengirim kembali pakaian ukuran sampel. Setelah pakaian ukuran sampel telah sampai pada kami, kami akan mengembalikan jaminan 100%. Biaya pengiriman ke kantor klien gratis dan biaya pengiriman kembali ke Gudang Seragam ditanggung oleh klien.",
    ],
  },
  {
    title: ["Desain", "GRATIS"],
    details: [
      "Klien tidak harus mempunyai desain seragam sebelum memesan seragam. Jika klien tidak mempunyai gambar, mengenai desain seragam yang akan dipesan, kami punya ribuan desain seragam yang dapat menjadi gambaran awal, dari seragam yang ingin Anda buat",
      "Jika klien mengalami kesulitan dalam mendesain seragam, tim desain kami siap membantu merancang seragam yang ingin Anda buat. Waktu pemrosesan 1-2 hari kerja.",
    ],
  },
  {
    title: ["Sampel", "Bordir", "GRATIS"],
    details: [
      "Logo sangat penting untuk identitas perusahaan. Ukuran logo, warna logo dan proporsi bentuk logo adalah hal yang sangat kami perhatikan. Oleh karena itu, kami selalu menyediakan sampel bordir untuk di ACC klien, sebelum produksi massal. Waktu pemrosesan 3-5 hari kerja.",
    ],
  },
  {
    title: ["Sampel", "Baju", "GRATIS"],
    details: [
      "Untuk pesanan minimal 50 pcs, kami membuat sampel pakaian seragam untuk menjadi patokan untuk pekerjaan massal. Biaya sampel dan pengiriman gratis. Jika klien setuju dengan sampel pakaian, maka klien mengembalikan pakaian sampel yang telah di ACC untuk menjadi tolak ukur.",
      "Jika masih ada revisi dari pakaian sampel yang dibuat, kami akan merevisi pakaian sampel sesuai dengan keinginan klien, tanpa biaya tambahan, selama mereka tidak membuat pakaian baru.",
      "Namun, apabila sampel kedua direvisi / pakaian sampel harus dibuat ulang, apakah karena perubahan bahan atau perubahan warna, itu akan dikenakan biaya 2x dari harga yang disepakati. Waktu pemrosesan maksimum 1 minggu.",
    ],
  },
  {
    title: ["Pengiriman", "GRATIS"],
    details: [
      "Untuk nilai pesanan di atas Rp5.000.000, biaya ongkos kirim pakaian seragam dipesan, untuk wilayah Jakarta gratis. Dan untuk daerah di luar Jakarta, dapatkan diskon Rp 100.000. Pengiriman seragam yang telah selesai dilakukan sehari setelah kami menerima pembayaran secara penuh.",
    ],
  },
];

export const order = [
  {
    title: ["Step", "Kesatu"],
    details: [
      `<strong>Klien memberikan desain</strong> / foto seragam yang akan dibuat. Silakan Klik tombol <strong>"Hubungi Kami"</strong>`,
    ],
  },
  {
    title: ["Step", "Kedua"],
    details: [
      'Jika klien tidak memiliki desain, kami memiliki ratusan desain alternatif yang dapat dilihat di situs web kami <a href="https://gudangseragam.com">GudangSeragam.com</a>',
    ],
  },
  {
    title: ["Step", "Ketiga"],
    details: [
      "Jika klien mengalami kesulitan membuat / mengubah desain seragam, kami memiliki tim desain yang siap membantu merancang seragam.",
    ],
  },
  {
    title: ["Step", "Keempat"],
    details: [
      "Setelah desain / foto seragam tersedia, tim CS kami akan menawarkan penawaran harga. Harga penawaran bervariasi, tergantung pada jenis bahan yang dipilih, tingkat kesulitan pembuatan dan jumlah yang dipesan.",
    ],
  },
  {
    title: ["Step", "Kelima"],
    details: [
      "Jika harga penawaran telah disetujui oleh klien, maka akan dilanjutkan ke proses selanjutnya:",
      "<ol type='A'><li>Mengunjungi kantor klien. Kunjungan ke kantor klien dapat dilakukan jika lokasi kantor klien berada di wilayah Jakarta, dengan jumlah pesanan minimum 50 pcs.</li><li>Membuat sampel. Pembuatan sampel bisa gratis jika jumlah yang dipesan minimal 50 pcs, dengan catatan bahwa sampel yang dibuat dimiliki oleh Gudang Seragam. Jika jumlah yang dipesan kurang dari 50 pcs, maka harga sampel adalah 3 kali harga penawaran dan pakaian sampel milik klien</li></ol type='A'>",
    ],
  },
  {
    title: ["Step", "Keenam"],
    details: [
      "Sebelum pekerjaan massal, klien memiliki hak untuk menerima Formulir Persetujuan Seragam Gudang yang berisi rincian tentang teknis pemesanan seragam.",
    ],
  },
  {
    title: ["Step", "Ketujuh"],
    details: [
      "Setelah formulir Persetujuan disetujui oleh klien, klien membayar DP seragam sebesar 50% dari total transaksi.",
    ],
  },
  {
    title: ["Step", "Kedelapan"],
    details: [
      "Jika klien telah melakukan DP, maka pekerjaan massal yang seragam akan mulai bekerja satu hari setelah DP diterima oleh Gudang Seragam.",
    ],
  },
  {
    title: ["Step", "Kesembilan"],
    details: [
      "Setelah seragam diproduksi, kami akan memotret seragam yang telah selesai diproduksi sebagai bukti bahwa seragam telah selesai. Foto-foto itu kemudian akan dikirim ke klien.",
    ],
  },
  {
    title: ["Step", "Kesepuluh"],
    details: [
      "Klien membayar sisa pembayaran beserta biaya pengiriman seragam. Klien bebas memilih ekspedisi yang digunakan untuk pengiriman seragam. <br /> DP dan Pembayaran hanya ditransfer ke akun berikut :",
      "BCA : 715-078-873-5 a/n Isnaeni Budi Prasetyo<br />Mandiri : 157-000-358-687-3 a/n Isnaeni Budi Prasetyo",
    ],
  },
];

export const clientData = [
  { img: "adhi.png" },
  { img: "sushi tei.png" },
  { img: "samsung.png" },
  { img: "Waskita.png" },
  { img: "leica.png" },
  { img: "erafone.png" },
  { img: "holycow.png" },
  { img: "KOPERASI INDONESIA.png" },
  { img: "kopi oey.png" },
  { img: "Logo Pertamina.png" },
  { img: "Logo_PLN.png" },
  { img: "Logo_PT_Kereta_Api_Indonesia_(Persero).png" },
  { img: "lotte-corporation.png" },
  { img: "nindya karya.png" },
  { img: "prudential.png" },
  { img: "telkomsel.png" },
  { img: "flip.png" },
  { img: "bca.png" },
  { img: "allianz.png" },
  { img: "metro_tv.png" },
  { img: "bank_mega.png" },
];

export const freeServices = [
  { img: "icon-01.svg", label: "Kunjungan" },
  { img: "icon-02.svg", label: "Pengukuran" },
  { img: "icon-03.svg", label: "Desain" },
  { img: "icon-04.svg", label: "Sample Bordir" },
  { img: "icon-05.svg", label: "Sample Baju" },
  { img: "icon-06.svg", label: "Pengiriman" },
];

export const productList: { name: string; img: string }[] = [
  { name: "Seragam<br />Pria", img: "seragam-01.jpg" },
  { name: "Seragam<br />Wanita", img: "seragam-02.jpg" },
  { name: "Seragam<br />Lapangan", img: "seragam-03.jpg" },
  { name: "Seragam<br />Security", img: "seragam-04.jpg" },
  { name: "Seragam<br />Teknisi", img: "seragam-05.jpg" },
  { name: "Seragam<br />Hotel", img: "seragam-06.jpg" },
  { name: "Seragam<br />Restauran", img: "seragam-07.jpg" },
  { name: "Seragam<br />Pabrik", img: "seragam-08.jpg" },
  { name: "Seragam<br />Spa", img: "seragam-09.jpg" },
  { name: "Seragam<br />Perawat", img: "seragam-10.jpg" },
  { name: "Seragam<br />Otomotif", img: "seragam-11.jpg" },
  { name: "Seragam<br />Komunitas", img: "seragam-12.jpg" },
];

export const measurementVideos = [
  {
    link: "https://www.youtube.com/embed/3uLAjEE1quM",
    title: "Video Cara Mengukur Panjang Baju",
    class: "d-none d-md-block",
  },
  {
    link: "https://www.youtube.com/embed/4Dr0LkH5bGQ",
    title: "Video Cara Mengukur Lebar Dada",
    class: "d-none d-md-block",
  },
  {
    link: "https://www.youtube.com/embed/_Qz8U40pRXI",
    title: "Video Cara Mengukur Lebar Pundak",
    class: "",
  },
  {
    link: "https://www.youtube.com/embed/pkKH-UtL0-E",
    title: "Video Cara Mengukur Lebar Perut",
    class: "",
  },

  {
    link: "https://www.youtube.com/embed/QbuAl067_YY",
    title: "Video Cara Mengukur Panjang Tangan <br /> (lengan panjang)",
    class: "",
  },
  {
    link: "https://www.youtube.com/embed/aHNoweBu8U0",
    title: "Video Cara Mengukur Panjang Tangan <br /> (lengan pendek)",
    class: "",
  },

  {
    link: "https://www.youtube.com/embed/Srjz0tojoEg",
    title: "Video Cara Mengukur Lingkar Pinggang",
    class: "",
  },
  {
    link: "https://www.youtube.com/embed/umKpBYYjMP0",
    title: "Video Cara Mengukur Lingkar Pinggul",
    class: "",
  },
  {
    link: "https://www.youtube.com/embed/A8BPhdroCC4",
    title: "Video Cara Mengukur Lebar Paha",
    class: "",
  },
  {
    link: "https://www.youtube.com/embed/YtgE2q4LM9M",
    title: "Video Cara Mengukur Pesak Celana",
    class: "",
  },
  {
    link: "https://www.youtube.com/embed/XgBGCaiIiIk",
    title: "Video Cara Mengukur Panjang Celana",
    class: "",
  },
  {
    link: "https://www.youtube.com/embed/k1RP0D9G9cM",
    title: "Video Cara Mengukur Lubang Kaki",
    class: "",
  },
  {
    link: "https://www.youtube.com/embed/SRGQF_8K1gg",
    title: "Video Cara Mengukur Panjang Gamis",
    class: "",
  },
];

export const materialVideos = [
  {
    link: "https://www.youtube.com/embed/6W8htMQE_R8",
    title: "Video Penjelasan Bahan Ventura Drill",
    class: "",
  },
  {
    link: "https://www.youtube.com/embed/qzMdR7nLB7w",
    title: "Video Penjelasan Bahan Lacoste CVC",
    class: "",
  },
  {
    link: "https://www.youtube.com/embed/eCMzTNOFpog",
    title: "Video Penjelasan Bahan Maryland Tropical",
    class: "d-none d-md-block",
  },
  {
    link: "https://www.youtube.com/embed/84HdQY1qbzY",
    title: "Video Penjelasan Bahan Proff",
    class: "d-none d-md-block",
  },
  {
    link: "https://www.youtube.com/embed/mM798NpYBls",
    title: "Video Penjelasan Bahan American Drill",
    class: "",
  },
  {
    link: "https://www.youtube.com/embed/XCcFJAwOwro",
    title: "Video Penjelasan Bahan Silky Twill",
    class: "",
  },
  {
    link: "https://www.youtube.com/embed/XjfbhSLaRIc",
    title: "Video Penjelasan Bahan Pop Molly",
    class: "",
  },
  {
    link: "https://www.youtube.com/embed/LMVyu0itoTs",
    title: "Video Penjelasan Bahan Oxford",
    class: "",
  },
  {
    link: "https://www.youtube.com/embed/9IuTbnCsgjk",
    title: "Video Penjelasan Bahan Katun Kombed",
    class: "",
  },
  {
    link: "https://www.youtube.com/embed/tLeh3P94T-4",
    title: "Video Penjelasan Japan Drill",
    class: "",
  },
  {
    link: "https://www.youtube.com/embed/F4oKEk8yO3k",
    title: "Penjelasan Bahan Baby Tery",
    class: "",
  },
  {
    link: "https://www.youtube.com/embed/sDzkbO0PKUo",
    title: "Penjelasan Bahan Piu",
    class: "",
  },
  {
    link: "https://www.youtube.com/embed/ZelLhT1uJOw",
    title: "Penjelasan Bahan Nike",
    class: "d-none d-lg-block",
  },
  {
    link: "https://www.youtube.com/embed/zyFUSLB3lnE",
    title: "Penjelasan Bahan Mikro",
    class: "d-none d-lg-block",
  },
  {
    link: "https://www.youtube.com/embed/xwWMj6RMer0",
    title: "Penjelasan Bahan Mikro Satin",
    class: "",
  },
  {
    link: "https://www.youtube.com/embed/3D3MH4Dmd-Q",
    title: "Penjelasan Bahan Despo",
    class: "",
  },
  {
    link: "https://www.youtube.com/embed/3h3-MhZOHX0",
    title: "Penjelasan Bahan Diadora",
    class: "",
  },
  {
    link: "https://www.youtube.com/embed/D2e2lbKCnhs",
    title: "Penjelasan Bahan Fleece",
    class: "",
  },
  {
    link: "https://www.youtube.com/embed/PzPvg4trpYw",
    title: "Penjelasan Bahan Lotto",
    class: "",
  },
  {
    link: "https://www.youtube.com/embed/Bkg9-L53Svs",
    title: "Penjelasan Bahan Mayer",
    class: "",
  },
  {
    link: "https://www.youtube.com/embed/uFL4EZaexJc",
    title: "Penjelasan Bahan Padding",
    class: "",
  },
  {
    link: "https://www.youtube.com/embed/lkggkSf3CxI",
    title: "Penjelasan Bahan Rispstock Army",
    class: "",
  },
  {
    link: "https://www.youtube.com/embed/_supvw_ORcI",
    title: "Penjelasan Bahan Taslan Balon",
    class: "",
  },
  {
    link: "https://www.youtube.com/embed/yVv81SdC87c",
    title: "Penjelasan Bahan Taslan Jarum",
    class: "",
  },
  {
    link: "https://www.youtube.com/embed/UTdMEJtfD20",
    title: "Penjelasan Bahan Taslan JN",
    class: "",
  },
  {
    link: "https://www.youtube.com/embed/IbM9xYNPrjE",
    title: "Penjelasan Bahan Taslan Milky",
    class: "",
  },
];

export const portoPictures = {
  seragam_pria: [
    "pria/Pria-01.jpg",
    "pria/Pria-02.jpg",
    "pria/Pria-03.jpg",
    "pria/Pria-04.jpg",
    "pria/Pria-05.jpg",
    "pria/Pria-06.jpg",
    "pria/Pria-07.jpg",
    "pria/Pria-08.jpg",
    "pria/Pria-09.jpg",
    "pria/Pria-10.jpg",
    "pria/Pria-11.jpg",
    "pria/Pria-12.jpg",
    "pria/Pria-13.jpg",
    "pria/Pria-14.jpg",
    "pria/Pria-15.jpg",
    "pria/Pria-16.jpg",
    "pria/Pria-17.jpg",
  ],
  seragam_wanita: [
    "wanita/wanita-01.jpg",
    "wanita/wanita-02.jpg",
    "wanita/wanita-03.jpg",
    "wanita/wanita-04.jpg",
    "wanita/wanita-05.jpg",
    "wanita/wanita-06.jpg",
    "wanita/wanita-07.jpg",
    "wanita/wanita-08.jpg",
    "wanita/wanita-09.jpg",
    "wanita/wanita-10.jpg",
    "wanita/wanita-11.jpg",
    "wanita/wanita-12.jpg",
    "wanita/wanita-13.jpg",
    "wanita/wanita-14.jpg",
    "wanita/wanita-15.jpg",
    "wanita/wanita-16.jpg",
    "wanita/wanita-17.jpg",
    "wanita/wanita-18.jpg",
    "wanita/wanita-19.jpg",
    "wanita/wanita-20.jpg",
    "wanita/wanita-21.jpg",
    "wanita/wanita-22.jpg",
  ],
  seragam_lapangan: [
    "lapangan/outdoor-01.jpg",
    "lapangan/outdoor-02.jpg",
    "lapangan/outdoor-03.jpg",
    "lapangan/outdoor-04.jpg",
    "lapangan/outdoor-05.jpg",
    "lapangan/outdoor-06.jpg",
    "lapangan/outdoor-07.jpg",
    "lapangan/outdoor-08.jpg",
    "lapangan/outdoor-09.jpg",
    "lapangan/outdoor-10.jpg",
    "lapangan/outdoor-11.jpg",
    "lapangan/outdoor-12.jpg",
    "lapangan/outdoor-13.jpg",
    "lapangan/outdoor-14.jpg",
    "lapangan/outdoor-15.jpg",
    "lapangan/outdoor-16.jpg",
    "lapangan/outdoor-17.jpg",
    "lapangan/outdoor-18.jpg",
    "lapangan/outdoor-19.jpg",
  ],
  seragam_security: [
    "security/security-01.jpg",
    "security/security-02.jpg",
    "security/security-03.jpg",
    "security/security-04.jpg",
    "security/security-05.jpg",
  ],
  seragam_teknisi: [
    "teknisi/teknisi-01.jpg",
    "teknisi/teknisi-02.jpg",
    "teknisi/teknisi-03.jpg",
    "teknisi/teknisi-04.jpg",
    "teknisi/teknisi-05.jpg",
    "teknisi/teknisi-06.jpg",
    "teknisi/teknisi-07.jpg",
  ],
  seragam_hotel: [
    "hotel/hotel-01.jpg",
    "hotel/hotel-02.jpg",
    "hotel/hotel-03.jpg",
    "hotel/hotel-04.jpg",
    "hotel/hotel-05.jpg",
    "hotel/hotel-06.jpg",
    "hotel/hotel-07.jpg",
    "hotel/hotel-08.jpg",
    "hotel/hotel-09.jpg",
    "hotel/hotel-10.jpg",
    "hotel/hotel-11.jpg",
    "hotel/hotel-12.jpg",
    "hotel/hotel-13.jpg",
    "hotel/hotel-14.jpg",
    "hotel/hotel-15.jpg",
    "hotel/hotel-16.jpg",
    "hotel/hotel-17.jpg",
    "hotel/hotel-18.jpg",
    "hotel/hotel-19.jpg",
    "hotel/hotel-20.jpg",
    "hotel/hotel-21.jpg",
    "hotel/hotel-22.jpg",
  ],
  seragam_restauran: [
    "restaurant/restauran-01.jpg",
    "restaurant/restauran-02.jpg",
    "restaurant/restauran-03.jpg",
    "restaurant/restauran-04.jpg",
    "restaurant/restauran-05.jpg",
    "restaurant/restauran-06.jpg",
    "restaurant/restauran-07.jpg",
    "restaurant/restauran-08.jpg",
    "restaurant/restauran-09.jpg",
    "restaurant/restauran-10.jpg",
    "restaurant/restauran-11.jpg",
    "restaurant/restauran-12.jpg",
    "restaurant/restauran-13.jpg",
    "restaurant/restauran-14.jpg",
    "restaurant/restauran-15.jpg",
    "restaurant/restauran-16.jpg",
  ],
  seragam_perawat: [
    "perawat/perawat-01.jpg",
    "perawat/perawat-02.jpg",
    "perawat/perawat-03.jpg",
    "perawat/perawat-04.jpg",
    "perawat/perawat-05.jpg",
    "perawat/perawat-06.jpg",
    "perawat/perawat-07.jpg",
    "perawat/perawat-08.jpg",
    "perawat/perawat-09.jpg",
    "perawat/perawat-10.jpg",
    "perawat/perawat-11.jpg",
    "perawat/perawat-12.jpg",
  ],
  seragam_spa: [
    "spa/spa-01.jpg",
    "spa/spa-02.jpg",
    "spa/spa-03.jpg",
    "spa/spa-04.jpg",
    "spa/spa-05.jpg",
    "spa/spa-06.jpg",
    "spa/spa-07.jpg",
    "spa/spa-08.jpg",
    "spa/spa-09.jpg",
    "spa/spa-10.jpg",
    "spa/spa-11.jpg",
  ],
  seragam_pabrik: [
    "pabrik/Pabrik-01.jpg",
    "pabrik/Pabrik-02.jpg",
    "pabrik/Pabrik-03.jpg",
    "pabrik/Pabrik-04.jpg",
    "pabrik/Pabrik-05.jpg",
    "pabrik/Pabrik-06.jpg",
    "pabrik/Pabrik-07.jpg",
    "pabrik/Pabrik-08.jpg",
    "pabrik/Pabrik-09.jpg",
    "pabrik/Pabrik-10.jpg",
    "pabrik/Pabrik-11.jpg",
    "pabrik/Pabrik-12.jpg",
    "pabrik/Pabrik-13.jpg",
  ],
  seragam_otomotif: [
    "otomotif/otomotif-1.jpg",
    "otomotif/otomotif-2.jpg",
    "otomotif/otomotif-3.jpg",
    "otomotif/otomotif-4.jpg",
    "otomotif/otomotif-5.jpg",
    "otomotif/otomotif-6.jpg",
    "otomotif/otomotif-7.jpg",
    "otomotif/otomotif-8.jpg",
    "otomotif/otomotif-9.jpg",
    "otomotif/otomotif-10.jpg",
  ],
  seragam_komunitas: [
    "komunitas/komunitas-1.jpg",
    "komunitas/komunitas-2.jpg",
    "komunitas/komunitas-3.jpg",
    "komunitas/komunitas-4.jpg",
    "komunitas/komunitas-5.jpg",
    "komunitas/komunitas-6.jpg",
    "komunitas/komunitas-7.jpg",
    "komunitas/komunitas-8.jpg",
  ],
};

export const ourClient: { [key: string]: string[] } = {
  a: [
    "AC CALL’S",
    "PT ADHI KARYA (PERSERO) TBK",
    "AFIAT MC PT",
    "ADIPERKASA EKABAKTI INDUSTRY",
    "AKM DIGITAL PRINTING",
    "PT ADYABINA",
    "AKUNTAN MA’HAD AMCF",
    "PT AERO FLYER INSTITUTE",
    "PT AGPU",
    "PT AGRIJAYA PRIMA SUKSES",
    "PT AIRINDO SAKTI",
    "PT AMETHYS HOLIDAY",
    "ALUMNI SMA 3 SEMARANG",
    "ALUMNI SMA NEGERI GRESIK",
    "ALUMNI SMA SULUH",
    "ALUMNI SMA TARUTUNG",
    "ALUMNI SMAN 158 PALOPO",
    "PT AMSECON BERLIAN SEJAHTERA",
    "AMAN BERKAT MOTOR",
    "PT ANEKA INDOFOIL",
    "ANEKA INDOFOIL",
    "PT ANITA IDO PRATAMA",
    "APARTEMEN BONAVISTA",
    "PT ANUGERAH KARYA TEKNIK",
    "APARTMEN BONAVISTA",
    "PT ANUGERAH MULYA LESTARI",
    "APL LOGISTIC",
    "PT APECO KENCANA",
    "APM LOGISTIC",
    "PT ARDA BANA UTAMA (SWALAYAN)",
    "A-SPEC",
    "PT ARIF RAHMAN PUTRA MANDIRI",
    "ASURANSI BANK MEGA",
    "PT ARISTA PRATAMA JAYA",
    "AUSTRALIA AWARDS INDONESIA	",
    "PT ARTA RAJA PRATAMA",
    "CV ANUGERAH SEMESTA ALAM	",
    "PT ARTAS ENERGI PETROGAS",
    "PT ABADI KARYA	PT A-SPEC",
    "PT ABDIYASA DHARMA INOVASI	",
    "PT ASURANSI UMUM MEGA",
    "PT AC CALLS",
    "PT ATLANTA TEKNO MEKANIKA",
    "PT ADDA JAYA INDONESIA",
    "PT AVAIL ELOK INDONESIA",
    "PT ADHI HUTAMA KSO",
  ],
  b: [
    "BABY SAFE",
    "PT BERKAT TRANS MANDIRI",
    "BADAN AMIL ZAKAT NASIONAL",
    "PT BEST QUALITY",
    "BAKSO MILENIAL",
    "PT BETA PRAMESTI ASIA",
    "BALI SUNSET VILLA",
    "PT BINAKARINDO YACOAGUNG",
    "BANK VICTORIA SYARIAH",
    "PT BINSAR NATORANG ENERGI",
    "BATU PERMAI HOTEL",
    "PT BINTANI MEGAHINDAH",
    "BELLEZA HOTEL",
    "PT BINTUNI ENERGI PERSADA",
    "BINTANG MAS LOGISTICS	",
    "PT BOGA ABYUDAYA INDONESIA",
    "BINTANG PAPUA",
    "PT BOGA PRIMA DIDAYA",
    "BPPT",
    "PT BOXALADIN ASIA PACIFIC",
    "BRI II",
    "PT BPR NUSANTARA BONA PASOGIT 2",
    "BRO PRINT",
    "PT BREAD MART INDONESIA",
    "B’STEAK & PANCAKE RESTAURANT",
    "PT BRINGIN BANK BRI",
    "BTK MART",
    "PT BSM NEW MATERIAL",
    "BUKIT BERAHU",
    "PT BUANA CITRA ABADI",
    "CV BATAVIA TATA MEDIA",
    "PT BUMI GRAHA PERKASA",
    "CV BINA PUSTAKA",
    "PT BUMI MAESTROAYU",
    "CV BUANA MAS",
    "PT BUMI MITRA INDUSTRI",
    "PT BAHARI",
    "PT BUMI MITRA INDUSTRI",
    "PT BANK MEGA",
    "PT BUNDA GLOBAL PHARMA",
    "PT BATRAT JAYA SEJAHTERA",
    "PT BUNNY SUKSESSELALU",
    "PT BERKAH INDO JASA",
    "PT BUSSAN AUTO FINANCE",
  ],
  c: [
    "PT CENTRAYASA SERVICES",
    "CITY TOWER",
    "PT CG INTERNATIONAL CONSTRUCTION",
    "CLUB SEHAT",
    "PT CIPTA KARYA",
    "CROYAKI RESTORAN",
    "PT CITICON ADHINUGRAHA",
    "CU CANAGA ANTUTN",
    "PT CITRA ADIARTHA SHIPPING",
    "PT CAKRAWALA ENERGI NUSANTARA",
    "PT CLIPAN FINANCE",
    "PT CAKRAWALA LANGIT SEJAHTERA",
    "PT CLP INDONESIA",
    "PT CANDIDA MABEL MUTIARA INDONESIA",
    "PT CRONA",
    "PT CASM",
    "PT CUNNINGHAM LINDSEY",
    "PT CEMPAKA MAS",
  ],
  d: [
    "DANTESCO KORI AGUNG",
    "PT DAYATURANGGA",
    "DGEX INDONESIA	",
    "PT DELTA PRIMA",
    "DINAS LINGKUNGAN HIDUP KOTA TANGERANG	",
    "PT DGL",
    "DINAS PERUMAHAN, KAWASAN PERMUKIMAN DAN PERTANAHAN	",
    "PT DIADYANI TIMBER",
    "DIVA LOMBOK",
    "PT DIAMASTAR",
    "DOBUDON",
    "PT DIPOSTAR FINANCE",
    "DUNIA WALLPAPER PT",
    "DRAGON FOREVER",
    "PT DAEHONG",
    "PT DUTA ENERGI MULIATAMA",
    "PT DANA KITA",
    "PT DUTA KEMAS INDONESIA",
    "PT DANTESCO KORI AGUNG",
    "PT DWIFARITA FAJARKHARISMA",
    "PT DAYA RADAR UTAMA",
  ],
  e: [
    "CV EZO BOGA SINERGI",
    "PT ERAFONE",
    "ESKA AESTHETIC CLINIC & MEDISPA",
    "PT ERZE BUMI HARMONI",
    "PT ECOGREEN",
    "PT ERZE HARMONI",
    "PT EDENSOR LAZUARDI",
    "PT ESA BUANA HUSADA",
    "PT ELIM DUTA DARMA",
    "PT ESKA DERMA INDONESIA",
    "PT ENERGY SAKTI SENTOSA",
    "PT ESKIMO WIERAPERDANA",
  ],
  f: [
    "FAKULTAS TEKNIK UI",
    "PT FASTINDO",
    "FIA UNIV. INDONESIA",
    "PT FOKUS CITRA PRIMA",
    "FKLPMK KOTA DEPOK",
    "PT FORWARD INDONESIA",
    "FORKABI",
    "PT FOUR SEVEN",
    "FTUI",
    "PT FRESHKLINDO",
    "PT F1",
    "PT FREYSSINET TOTAL TECHNOLOGY",
    "PT FAJAR MANDIRI",
    "PT FUJIKURA INDONESIA",
    "PT FAST BEAUTY",
    "PT FUMIDA PESTINDO JAYA",
  ],
  g: [
    "GAMA BROTHER",
    "PT GLOBAL BASKET MULIA INVESTAMA",
    "GAME MASTER",
    "PT GLOBAL TEKNOLOGI SENTOSA",
    "GLOBAL INTEGRATED S",
    "PT GRAHA CIPTA SUKSES",
    "GRAHA BINA ASUH",
    "PT GRAHA INTI PERMAI",
    "GUDANG KOPI OEY",
    "PT GRAHA MITRA SUKSES",
    "PT GADING",
    "PT GRAHA NIAGA TATA UTAMA",
    "PT GAGAS ENERGY INDONESIA	",
    "PT GRAMA BAZITA",
    "PT GAMA PRIMA",
    "PT GREEN PLANET",
    "PT GCC SERVICE INDONESIA",
    "PT GTI INDONESIA",
    "PT GLI MITRA PERKASA",
    "PT GUO YAO",
  ],
  h: [
    "HARVEST CITY",
    "PT HARAPAN MAJU JAYA",
    "HENGJAYA MINERALINDO",
    "PT HARIO CAFE INDONESIA",
    "HITACHI MODERN SALES",
    "PT HASSANA BOGA",
    "HOLYCOW",
    "PT HAZAMA ANDO MURINDA",
    "HOTEL ARJUNA",
    "PT HENGJAYA MINERALINDO",
    "HOTEL RO & VI",
    "PT HITACHI MODERN SALES INDONESIA",
    "PT HALARAG BAJA",
    "PT HK ADHI HUTAMA",
    "PT HANESA PRIMA",
    "PT HYPER MEGA SHIPPING",
    "PT HANIORI",
  ],
  i: [
    "IACCB",
    "PT INDO STATIONERY RITEL UTAMA",
    "ICSF",
    "PT INDOMEDIKA UTAMA",
    "IKRADA",
    "PT INDOMIKA UTAMA",
    "ISD SERVICE",
    "PT INDOMODULE PRATAMA",
    "PT ICAST ASIA NETWORKS	",
    "PT INDOPRIMA",
    "PT ICAST ASIA NETWORKS	",
    "PT INDOPRIMA BIONET",
    "PT INDO DHARMA TRANSPORT",
    "PT INDOTARA",
    "PT INDO KOPPE ENJINIRING",
    "PT INTIKOM BERLIAN MUSTIKA",
    "PT INDO PASIFIK KREASI UTAMA",
    "PT INVESTAMA",
  ],
  j: [
    "JAKARTA ORTHODONTICS & DENTAL IMPLANT CENTER	",
    "PT JAWA ELANG TANGGUH",
    "JAKARTA SMARTCITY",
    "PT JAYA ANDALAS",
    "JJ CATERING",
    "PT JEMLA FERRY",
    "PT JABONTARA",
    "PT JIMMULYA",
    "PT JAMKRIDA JAKARTA",
    "PT JOAH & JOAL LOGISTIC",
    "PT JASA MARGA",
    "PT JONGKA INDONESIA",
  ],
  k: [
    "KEMENTERIAN KELAUTAN DAN PERIKANAN",
    "KPP. PRATAMA JAKARTA SAWAH BESAR II",
    "KEMENTERIAN KEUANGAN",
    "KREASI SUKSES INDOPRIMA",
    "KKM BONE",
    "PT KABATAMA RAYA",
    "KLINIK BAHAR MEDIKA",
    "PT KAJAMA",
    "KLINIK GMC",
    "PT KARSA MUDIKA",
    "KLINIK SYIFA",
    "PT KARYA MANDIRI GLOBALINDO",
    "KOPERASI CITRA MARGA",
    "PT KETIRA",
    "KOPERASI INDONESIA",
    "PT KHATULISTIWA SINERGI",
    "KOPERASI KARYAWAN CARDIG",
    "PT KOKA INDONESIA",
    "KOPERASI LIMA GARUDA",
    "PT KONAN INDONESIA",
    "KOPERASI LIMA VENTURA",
    "PT KONSULINDO INFORMATIKA",
    "KOPERASI PEGAWAI DAN PERIZINAN LISTRIK NEGARA",
    "PT KRAKATAU STEEL",
    "KOPKAR USAHA BERSAMA",
    "PT KREASI SUKSES INDOPRIMA",
    "KOPPELIN",
    "PT KSO WASKITA EKAPRATAMA",
    "KPP PELAYANAN PAJAK PRATAM ARGAMAKMUR",
    "PT KUMALA TEKNIK",
  ],
  l: [
    "CV LIMA SATU ABADI	",
    "PT LEICA GEOSYSTEM",
    "LAFAYETTE HOTEL",
    "PT LELCO TRINDO GRAHA NUSANTARA",
    "LEMBASUNG HOTEL",
    "PT LIMA CITA",
    "LEZZA",
    "PT LINTEC",
    "LIQUOR SHOP RESTORAN",
    "PT LISTRIK VINE INDUSTRI",
    "LM FEB UI",
    "PT LUKI SILK NUETRUSTIE",
    "LUXIO HOTEL",
    "PT LUSHAURA PESONA INDONESIA",
    "PT LAJU USAHA GEMILANG",
    "PT LYNAS MEDIKA",
    "PT LANTABURA INTERNASIONAL",
    "PT LYNEA PRIMISIMA",
    "PT LAUNDROMAT",
    "PT LYTO DATARINDO FORTUNA",
    "PT LAUTAN KENCANA HIDUP",
  ],
  m: [
    "MAESTRO GROUP",
    "PT MEGA MANUNGGAL",
    "MAESTRO HOTEL",
    "PT MEGA PUTRA",
    "MANEKA JAYA KREATIF",
    "PT MEGADAYA TANGGUH",
    "MARITZA MART",
    "PT MEGUMI PARAS CANTIKA",
    "MARTABAK SINAR TERANG",
    "PT MERBAUJAYA INDAH RAYA",
    "MAXIM FRUIT MARKET",
    "PT MESITECHMITRA PURNABANGUN",
    "MENARA CITICON",
    "PT MESSA NUGRA NIRWANA",
    "MGM KARAOKE",
    "PT META ADHYA TIRTA UMBULAN",
    "MID UI",
    "PT MIKRO JAYA",
    "MILENIUM MOTOR	",
    "PT MITRA ABADI MAHAKAM",
    "MOCKING BIRD",
    "PT MITRA ABADI SELATAN",
    "MR CHIEN RESTORAN",
    "PT MITRA ARTA SUKSES",
    "MR CUAN RESTORAN",
    "PT MITRA DUTA CATERING",
    "PT M3 MOVERS",
    "PT MITRA KERJA UTAMA",
    "PT MACROTERMIX",
    "PT MITRA KONSTRUKSI PERSADA",
    "PT MAHARTOMO UTAMA DESAIN",
    "PT MITRA MANDIRI INDOENERGI",
    "PT MALEO RAJA INDONESIA",
    "PT MITRA MUTIARA MANDIRI",
    "PT MANADO JAYA SENTOSA	",
    "PT MITRA PERSADA PRIMARINDO",
    "PT MANGGALA ALAM LESTARI",
    "PT MITRA SENTOSA PLASINDO",
    "PT MASTER WEB",
    "PT MODERN DATA SOLUSI",
    "PT MBA CONSULTING",
    "PT MOEY JAYA ABADI",
    "PT MCC",
    "PT MOL AUTO CARRIER",
    "PT MCLOGI ARK INDONESIA",
    "PT MULTI GRAHA TEKNIKA",
    "PT MEDCOPAPUA INDUSTRI LESTARI",
    "PT MULTI INTI FINANCIAL TEKNOLOGI",
    "PT MEGA DHARMA",
    "PT MULTIMODA SARANA",
  ],
  n: [
    "NANO SALON",
    "PT NET SOLUTION",
    "NASI BAKAR 88",
    "PT NEU",
    "NOVILLA BOUTIQUE RESORT",
    "PT NEXT CREATIVE",
    "NUR PERABOT",
    "PT NINDYA KARYA",
    "PT NAGA KOMODO",
    "PT NIPA KARYA PERSADA",
    "PT NAYUMI SAM TOWER",
    "PT NUSAKURA STANDARINDO",
    "PT NCR INDONESIA",
    "PT NUSANTARA BERSATU",
    "PT NEBULA SURYA CORPORA",
    "PT NUTRIWELL",
  ],
  o: ["PT OPTIK TUNGGAL SEMPURNA", "OGHIE", "OPTIK TUNGGAL"],
  p: [
    "P3MD",
    "PT PATRA JASA",
    "PARTAI AMANAT NASIONAL",
    "PT PAWA INDONESIA",
    "PD. ROSSY",
    "PT PENDIDIKAN MARITIM DAN LOGISTIK INDONESIA",
    "PELINDO 4",
    "PT PERFECTO AD",
    "PERMATA LINTAS BUANA",
    "PT PERTAMINA",
    "PERSATUAN WARTAWAN INDONESIA	",
    "PT PERTAMINA MOR III",
    "PERTAMIN",
    "PT PERTAMINA RETAIL",
    "PERTAMINA PERKAPALAN",
    "PT PLN BANJARMASIN",
    "PERTAMINA SHIPPING	",
    "PT PLN PERSERO",
    "PETIK",
    "PT POP DIGITAL",
    "PLAZA PERMAI",
    "PT PRIMA AGRA KENCANA",
    "POLITEKNIK NEGERI JAKARTA	",
    "PT PRIMASOURCE ASIA",
    "PRS LESTARI",
    "PT PUPUK KALTIM",
    "PT PAPUA PURNAMA AGUNG	",
    "PT PUSAKA JAYA INTI SERVIS",
    "PT PARADISE PERKASA",
    "PT PUTRAMAS",
    "PT PARAMARTA UTAMA	",
    "PUMA SHOOTING CLUB",
  ],
  r: [
    "CV RAMA ENGINEERING",
    "RESTORAN GOOM",
    "CV RIANA SAKTI UTAMA",
    "RESTORAN RINDU KAMPOEN",
    "CV RIZKI JAYA ABADI",
    "REVITA BAKER",
    "PT RADIO CAKRAWALA BUANA SWAR",
    "RGI AL AZHA",
    "PT RAFA LINTAS CAKRAWALA",
    "ROYAL LAS VEGAS CAF",
    "PT RAZAKI ALIHDAYA TEKNOLOGI",
    "ROYAL LAS VEGAS LOUNG",
    "PT REKAYA ENGINEERING",
    "RUMAH GADA",
    "PT RESWARA CIPTA WISATA",
    "RUMAH MAKAN BUND",
    "PT RESWARA PRIMA EXPRESS",
    "RUMAH SAKIT DAERAH IDAMA",
    "PT RETRO DAYA ENGINEERING",
    "RUMAH SAKIT FATIM",
    "PT ROBICO",
    "RUMAH SAKIT KETERGANTUNGAN OBA",
    "PT ROYAL FOAMINDO PERKASA",
    "RUMAH SAKIT UTAM",
    "RAY WHITE",
    "RUMAH SYARIAHK",
    "RESTORAN BUNGO RAY",
  ],
  s: [
    "CV STEBERS",
    "PT SOLUSINDO MAJUTAMA",
    "PT SAGA MOVERS",
    "PT S-PRINT",
    "PT SAHABAT ABADI",
    "PT STAMINA INFINITE",
    "PT SAHATI HOTEL",
    "PT SUGIH BERKAT",
    "PT SAMBAS MINERALS MINING",
    "PT SUGIH BERKAT",
    "PT SANKO DENKI",
    "PT SUMBER ALAM SEKURAU",
    "PT SANOFI AVENTIS",
    "PT SUMBER KREASI GEMILANG",
    "PT SATKOMINDO MEDIYASA",
    "PT SUNANGRUP",
    "PT SATU",
    "PT SURYA ABADI",
    "PT SATUTOURS",
    "PT SURYA MANDIRI MUTUTAMA",
    "PT SEA ASIH LINES",
    "PT SURYADI",
    "PT SECURINDO PACKATAMA INDONESIA",
    "PT SWADAYA SAPTA PUTRA",
    "PT SELANGIS",
    "PT SWARGA LOKA DINAMIKA",
    "PT SELARAS INTI SEMESTA",
    "SAINT CINNAMONS",
    "PT SELEKTA",
    "SALON BEAUTY KOREA",
    "PT SERIM INDONESIA",
    "SALON TRESSEMME",
    "PT SETIA KAWAN ABADI",
    "SERAGAM SAFETY",
    "PT SGS MOTOR",
    "SERIKAT BURUH SEJAHTERA",
    "PT SHAKLEE",
    "SHANKUSEN BABY SHOP & FACTORY OUTLET",
    "PT SHINING STARS",
    "SHIHLIN",
    "PT SILVERY DRAGON",
    "SINAR ELEKTRONIK",
    "PT SIMAS REINSURANCE BROKERS",
    "SINTITI GRUP",
    "PT SINAR KAPUAS PRATAMA",
    "SMAIT RAHMANIYAH",
    "PT SINAR KURNIA UTAMA",
    "SMK 1 LUBUK DALAM",
    "PT SINAR REJEKI BERSAMA",
    "SMK LENTERA HARAPAN",
    "PT SINERGI MEGAH INTERNUSA",
    "SOLO SWA",
    "PT SINERGI UTAMA PERSADA",
    "SOLO SWALAYAN",
    "PT SK METALINDO",
    "SOMERSET CAFÉ",
    "PT SLS BEARINDO",
    "SSG COMMUNITY",
    "PT SMART",
    "SUGA RUSH",
    "PT SMARTEKNO",
    "SUKU DAYAK MERATUS",
    "PT SOLUSI REKATAMA",
    "SWISS BELL HOTEL",
  ],
  t: [
    "PT TANGERANG LIVE",
    "PT TRILOGAM INDOJAYA",
    "PT TARUMA JAYA UTAMA",
    "PT TRIMATRA LIGUNA",
    "PT THE BEST SWALLOW",
    "PT TUNAS GRYA MANDIRI",
    "PT THE NEXT CREATIVE",
    "THE FOOFTOP CAFE",
    "PT TIARA RAYA BALI INTERNASIONAL",
    "THE NEXT CREATIVE",
    "PT TIFANY USAHA MANDIRI",
    "TOBALI PUTRA PRODUCTIONS",
    "PT TIGA RAKSA SATRIA",
    "TOKO BOBO",
    "PT TIMOR NUSA DIRGANTARA",
    "TOKO EDY BAN",
    "PT TOP CAREER",
    "TOKO FURNITURE RUMAH KITA",
    "PT TOTALINDO",
    "TOKO KANA FAKFAK",
    "PT TRAN SMART",
    "TOKO RIA WANGI",
    "PT TRANS BAGUS DIGITALINDO",
    "TOKO SAN",
    "PT TRIBHAKTI INSPEKTAMA",
    "TULSA FUN EXPRESS",
    "PT TRIJAYA MOTOR",
  ],
  u: [
    "PT UCT",
    "PT UNGGUL CIPTA TRANS",
    "PT UNIRAMA",
    "UNIVERSAL DIGITAL SCALE",
    "UNIVERSITAS BINA NUSANTARA",
    "UNIVERSITAS INDRAPRASTA PGRI",
    "UNIVERSITAS TRISAKTI",
    "UPN VETERAN",
    "UPTD/SAMSAT MANOKWARI",
  ],
  v: [
    "PT VARNION",
    "PT VICTORI PERKASA SEJAHTERA",
    "PT VINE LISTRIK INDUSTRI",
    "V3S COMPUTER",
    "VALET PARKING",
    "VIJI CLINIC",
    "VILLA BINTANG LIMA MANOKWARI",
  ],
  w: [
    "PT WAHANA ELOK LESTARI",
    "PT WAHANA SUGIH",
    "PT WARTSILA INDONESIA",
    "PT WHOTO INDONESIA SEJAHTERA",
    "PT WIJAYA KARYA",
    "PT WIKON – BUKAKA, KSO",
    "PT WIRATMAN",
    "WINS CAFFE",
    "WONG AKBAR",
  ],
  x: ["PT XACTI INDONESIA"],
  y: [
    "PT YOSUA BERHASIL",
    "YAYASAN AL AZHAR",
    "YAYASAN AL QALAM",
    "YAYASAN AMCF",
    "YAYASAN KESEHATAN MASYARAKAT",
  ],
  z: ["ZAHRA TEAM"],
};
